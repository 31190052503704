import React from "react"
import SeO from "../components/seo"
import { graphql, StaticQuery } from 'gatsby'

function formatDate(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('-');
}

const TimeTable = (props) => (
  <StaticQuery
        query={graphql`
          query GetAllTimetableRows {
            allTimetableJson {
              edges {
                node {
                  start
                  description
                  backgroundColor
                }
              }
            }
          } 
        `}
        render={data=>(
          
          <React.Fragment>
            <SeO title="Χρονοδιάγραμμα" description="Χρονοδιάγραμμα" />
              <div className="timeline is-centered">
                <header className="timeline-header">
                  <span className="tag is-medium is-primary">Έναρξη</span>
                </header>
                  {
                    data.allTimetableJson.edges.map((item, index) => (
                      <div className="timeline-item" key={index}>
                        <div className="timeline-marker" style={{backgroundColor: item.node.backgroundColor}}></div>
                        <div className="timeline-content">
                          <p className="heading">{formatDate(item.node.start)}</p>
                          <p>{item.node.description}</p>
                        </div>
                      </div>
                        
                    ))
                  }
                <header className="timeline-header">
                  <span className="tag is-medium is-primary">Πέρας</span>
                </header>
            </div>
          </React.Fragment>
        )}
  />
)

/*
function TimeTable(props) {
  return (
    <React.Fragment>
      <SEO title="Χρονοδιάγραμμα" description="Χρονοδιάγραμμα" />
      Εδώ θα βρείτε το χρονοδιάγραμμα των ενεργειών που λαµβάνουν χώρα και αφορούν την
    υποβολή και έλεγχο των δικαιολογητικών, καθώς και τις λοιπές διαδικασίες που
    ακολουθούν µέχρι και την πρόσκληση των επιτυχόντων/ουσών υποψηφίων στη Σχολή επιτυχίας τους.
    </React.Fragment>

  );
}
*/

export default TimeTable